import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "template", "drag"]
  static values = {
    includeH3: Boolean, 
    includeRichText: Boolean,
  }

  headings = [];

  connect() {
    this.findHeadings();
    this.headings.forEach((heading, index) => {
      this.createListItem(heading, index);
    });

    /* Hide the entire table of contents if there are no headings to show */
    if(this.headings.length == 0) {
      $(this.element).addClass("d-none");
    }
  }

  findHeadings() {
    const sections = Array.from($(".page-wrapper section"));

    /* Find all sections with H2 headings */
    sections.forEach(section => {
      const sectionHeading = section.querySelector("h2:first-of-type");
      if (sectionHeading && sectionHeading.innerText && section == sectionHeading.closest("section")) {

        /* Don't add entries for children of accordions - links won't work if accordion is closed */
        let closestAccordion =  sectionHeading.closest(".accordian");
        if(closestAccordion == null || closestAccordion.closest("section") == section) {

          let htmlId = section.id;
          if(!htmlId) {
            htmlId = this.textToAnchor(sectionHeading.innerText);
            sectionHeading.id = htmlId;
          }  
          this.headings.push({ htmlId: htmlId, text: sectionHeading.innerText, element: section });          
        }
      }
    })

    /* Find all rich text (ActionText) objects with H2 headings, if requested */
    if (this.includeRichTextValue) {
      const trixHeadings = Array.from($(".trix-content h2"));
      trixHeadings.forEach(headingElement => {
        const text = headingElement.innerText;
        const htmlId = this.textToAnchor(text);
        headingElement.id = htmlId;

        /* Ensure no duplicate listings, which can happen if a section doesn't 
        have an H2 title so the above logic matches the first rich text H2 instead */
        if(!this.headings.some(h => h.htmlId === htmlId)) {
          this.headings.push({ htmlId: htmlId, text: text, element: headingElement });
        }
      });

      /* If we have generated headings from both sections and rich text,
      ensure they are displayed in the correct (DOM) order */
      if(sections.length > 0 && trixHeadings.length > 0) {
        this.headings.sort((a, b) =>
          a.element.compareDocumentPosition(b.element) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
        );
      }
    }
  }

  createListItem(heading, index) {
    if (heading) {
      let content = this.templateTarget.innerHTML
                      .replace(/ID/g, `toc-${index}`)
                      .replace(/ANCHOR/g, heading.htmlId)
                      .replace(/SECTION_TITLE/g, heading.text)
      this.listTarget.insertAdjacentHTML('beforeend', content)
    }
  }

  highlightListItem(listItem) {
    listItem.addClass('highlighted');
    this.dragTarget.style.top = `${listItem.offset().top - $(this.element).offset().top -8}px`;
  }

  onScroll() {
    var viewportTop = $(window).scrollTop() + 100;
    var viewportBottom = viewportTop + $(window).height();
    var hasHighlighted = false;
    var nearestOffscreenHeading = null;
    var nearestOffscreenHeadingDist = -100000;
    
    // Look through all headings to find the ones that are currently visible
    for (var i = 0; i < this.headings.length; i++) {
      var headingEl = $(this.headings[i].element);
      var elementTop = headingEl.offset().top;
      var elementBottom = elementTop + headingEl.height();
      var listItem = $(`#toc-${i}`);

      // Headings are in DOM order (top to bottom) so we can highlight the first one that's visible
      if (!hasHighlighted && elementTop > viewportTop && elementBottom < viewportBottom)
      {
        this.highlightListItem(listItem);
        hasHighlighted = true;
      } 
      else
      {
        listItem.removeClass('highlighted');

        // Non-visible headings might still be highlighted (see logic below)
        // so keep track of which one is nearest the top of the viewport
        var toViewportTopDist = elementTop - viewportTop;
        if (toViewportTopDist < 0 && toViewportTopDist > nearestOffscreenHeadingDist) {
          nearestOffscreenHeadingDist = toViewportTopDist;
          nearestOffscreenHeading = listItem;
        }
      }
    }

    // If no headings are visible, highlight the one closest to the top of the viewport
    if(!hasHighlighted && nearestOffscreenHeading != null) {
      this.highlightListItem(nearestOffscreenHeading);
    }
  }

  textToAnchor(text) {
    return text.replace(/[^\w\s]/g, "").toLocaleLowerCase().split(' ').join('-');
  }
}
