import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['input', 'errorMessage'];
  static values = { required: Boolean }

  connect() {
    this.inputTarget.addEventListener("trix-change", function() { this.onTrixChange() }.bind(this));
    this.invalid = false;
    this.updateHeight();
  }

  onTrixChange() {
    this.validate();
    this.updateHeight();
  }

  validate() {
    if(this.requiredValue && !this.inputTarget.hasChildNodes()) {
      this.inputTarget.classList.add("is-invalid");
      this.errorMessageTarget.classList.remove("d-none");
      this.invalid = true;
    } 
    else {
      this.inputTarget.classList.remove("is-invalid");
      this.errorMessageTarget.classList.add("d-none");
      this.invalid = false;
    }
  }

  /* Auto expand height to match contents */
  updateHeight() {
    this.inputTarget.style.height = this.inputTarget.scrollHeight;
  }

}